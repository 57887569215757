import { Box, Grid } from '@mui/material'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import PaymentForm from '../components/PaymentForm'

const PaymentPass = ({ publicKey, clientSecret, details, sharingWith, signUpForm,productId }) => {
    const stripePromise = loadStripe(
        publicKey);
    const options = { clientSecret };

    return (
        <div>
            <Grid item xs={12} md={12}>
                <Box sx={{}} p={2}>

                    <Elements options={options} stripe={stripePromise}>
                        <PaymentForm
                            details={details}
                            sharingWith={sharingWith}
                            signUpForm={signUpForm}
                            productId={productId}
                        />

                    </Elements>
                    {/* // @ts-ignore */}
                </Box>
            </Grid>
        </div>
    )
}

export default PaymentPass

// @ts-nocheck

import { useEffect, useState } from "react";

import { Box, Grid, Typography } from "@mui/material";
import AppBarComponent from "../components/appbar";
import { useGetPasses, useGetStripePublicKey } from "./core/_requests";
import { useParams } from "react-router-dom";
import PassDetails from "../stepper/PassDetails";
import SignUpForm from "../stepper/SignupForm";
import SharingPass from "../stepper/SharingPass";
import { useAuth } from "../hooks/useAuth";
import PaymentPass from "../stepper/PaymentPass";
import CustomBottomBar from "../components/CustomBottomBar";


const Room = () => {
    const { productId } = useParams();
    console.log('productId',productId)
    const auth = useAuth();

    const { data: detailsPass, isSuccess } = useGetPasses({ id: productId, })
    const { data: stripePublishKey, isSuccess: stripeIsSuccess } = useGetStripePublicKey()
    const [signUpForm, setSignUpForm] = useState({
        first_name: "",
        last_name: "",
        email: "",
        pass_id: productId

    });
    const [sharingWith, setSharingWith] = useState([
        {
            first_name: "",
            last_name: "",
            email: "",

        }
    ])
    const [formErrors, setFormErrors] = useState(null)
    const [activeStep, setActiveStep] = useState(0);
    const [loading, setLoading] = useState(false)
    const steps = ["Pass Details", "Signup informations", "Share", "Checkout",];

useEffect(() => {
    detailsPass&&

  setSignUpForm({
    ...signUpForm,
    pass_id: detailsPass?.reference
  })
}, [detailsPass])



    const handleNext = () => {
        setActiveStep((prevStep) => {
            console.log('prevStep', prevStep)
            // Check if we need to move to the next step directly
            if (prevStep === 1 && detailsPass?.sharing == 0) {
                // Return the new step number directly
                return prevStep + 2;
            }
            // Otherwise, return the incremented step number
            return prevStep + 1;
        });
      
    };

    const handleBack = () => {
        setActiveStep((prevStep) => {
            // Check if we need to move to the next step directly
            if (prevStep === 3 && detailsPass?.sharing == 0) {
                // Return the new step number directly
                return prevStep - 2;
            }
            // Otherwise, return the incremented step number
            return prevStep - 1;
        });
        // setActiveStep(activeStep - 1);
    };


    const handleSignupUser = async () => {
        setLoading(true)
        try {
          const data={
            ...signUpForm,
          
          }
          const response = await auth.register(data);
         
          if (response.success) {
            setLoading(false)
            setActiveStep((prevStep) => {
                console.log('prevStep', prevStep)
                // Check if we need to move to the next step directly
                if (prevStep === 1 && detailsPass?.sharing == 0) {
                    // Return the new step number directly
                    return prevStep + 2;
                }
                // Otherwise, return the incremented step number
                return prevStep + 1;
            });
          } else {
            setFormErrors(response.message.response.data.errors)
            setLoading(false)
          }
        } catch (e) {
            setLoading(false)
        }
      };


    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: "100vh",
                backgroundColor: "#e2e2e2",
            }}
        >
            <AppBarComponent
                passName={detailsPass?.entitled}
                steps={steps}
                activeStep={activeStep}
            />
            <Box sx={{ flexGrow: 1, overflow: "auto", p: 2, mb: 5 }}>
                {isSuccess && (
                    <>
                        {activeStep === 0 && (
                            <PassDetails details={detailsPass} />
                        )}
                        {activeStep === 1 && (
                            <>
                                <SignUpForm
                                    signUpForm={signUpForm}
                                    setSignUpForm={setSignUpForm}
                                    formErrors={formErrors}
                                    setFormErrors={setFormErrors}
                                />
                                <Grid container spacing={3} textAlign={"end"}>
                                    <Grid item xs={12} sm={12}>
                                        <Typography fontSize={15}> <span style={{ color: "red", fontSize: "28px", fontWeight: "000" }} >*</span>  all fields are required            </Typography>
                                    </Grid>



                                </Grid>
                            </>
                        )}
                        {activeStep === 2 && (
                            <SharingPass
                                sharingWith={sharingWith}
                                setSharingWith={setSharingWith} />
                        )}

                        {activeStep === 3 && (
                           
                            <PaymentPass 
                            publicKey={stripePublishKey?.public_key}
                            details={detailsPass} 
                            sharingWith={sharingWith}
                            signUpForm={signUpForm}
                            productId={productId}
                            clientSecret={localStorage.getItem('client_secret')}
                            />
                        )}
                    </>
                )}
            </Box>

            <CustomBottomBar
                steps={steps}
                activeStep={activeStep}
                handleNext={activeStep==1? handleSignupUser: handleNext}
                // handleNext={handleNext}
                isPending={loading}
                handleBack={handleBack}

            />
        </Box>
    );
};
export default Room;

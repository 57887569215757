//@ts-nocheck
import React, { useMemo, useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import "../styles/payment-form.scss";
import { Box, CircularProgress, Grid, } from "@mui/material";

import useResponsiveFontSize from "../useResponsiveFontSize";
import { useCreateOrdersPass, useGetExtraData } from "../Pages/core/_requests";
import BookingSucces from "../stepper/BookingSucces";

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

const PaymentForm = ({ details, sharingWith, signUpForm, productId }) => {
  const { data: extraData } = useGetExtraData()
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [loading, setLoading] = useState(false);

  const [isCardComplete, setIsCardComplete] = useState(false);
  const [isExpiryComplete, setIsExpiryComplete] = useState(false);
  const [isCvcComplete, setIsCvcComplete] = useState(false);
  const createOrderPassMutation = useCreateOrdersPass()


  const handleSubmit = async (event) => {
    event.preventDefault();
    if (details?.price == 0) {
      handleStorePass()
    } else {
      handleStoreWithStripe()
    }
  }
  const handleStoreWithStripe = async () => {

    setLoading(true);

    if (!stripe || !elements) {
      setLoading(false);
      return;
    }

    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
    });
    setLoading(false);

    if (payload.error) {

    } else {

      const card = payload?.paymentMethod?.card;

      const PaymentId = payload?.paymentMethod?.id;
      if (card) {

        handleStorePass(card.exp_month, card.exp_year, card.last4, PaymentId)
      }
    }
  };



  const handleStorePass = async (exp_month, exp_year, last4, PaymentId) => {
    const shared = sharingWith?.filter((item) => item.email !== "")
    console.log('shared', shared)

    const data = {
      price: details?.price,
      sharedWith: shared,
      d_pass_id: productId,
      d_client_id: localStorage.getItem('client_id'),
      // payment: {
      payment_reference: details?.price == 0 ? "Free" : PaymentId,
      payment_method: details?.price == 0 ? "3" : '1',

      payment_method_id: details?.price == 0 ? "Free" : PaymentId,
      card_number: last4,
      month: exp_month,
      year: exp_year,
      default: 1
      // }
    }
    try {
      await createOrderPassMutation.mutateAsync(data)
      window.localStorage.clear('client_secret',)
      window.localStorage.clear('client_id',)

    } catch (error) {
      // setFormErrors(error?.response?.data?.errors)
    }
  }
  console.log('stripe || !isCardComplete || !isExpiryComplete || !isCvcComplete || loading', stripe, isCardComplete, isExpiryComplete, isCvcComplete, loading)
  return (
    <>
      {createOrderPassMutation?.isSuccess ? <BookingSucces details={details} productId={productId} /> : <form
        className="payment-form"
        style={{ width: "100%", display: "flex", flexDirection: "column" }}
        onSubmit={handleSubmit}
      >
        {details?.price !== 0 &&
          <Grid container spacing={2} >
            <Grid item xs={12} md={8} >

              {/* Card number */}
              <CardNumberElement
                options={options}
                onChange={(event) => {
                  setIsCardComplete(event.complete);
                }}
              />

            </Grid>
            <Grid item xs={12} md={2} >
              <CardExpiryElement
                options={options}
                onChange={(event) => {
                  setIsExpiryComplete(event.complete);
                }}
              />
            </Grid>
            <Grid item xs={12} md={2} >
              <CardCvcElement
                options={options}
                onChange={(event) => {
                  setIsCvcComplete(event.complete);
                }}
              />
            </Grid>

          </Grid>}
        <Grid container spacing={2} >
          <Grid item xs={12} md={12} >
            <div className="total" >
              <span>Total:</span>
              <span style={{ fontSize: "25px" }}>{details?.price == 0 ? "Free" : details?.price}</span>
            </div>
          </Grid>
        </Grid>

        <div className="terms">
          <span style={{ fontSize: "16px" }}>
            By clicking "Complete Order", you are agreeing to our{" "}
            <a href={extraData?.term_of_services_url}>terms of service</a> and <a href={extraData?.privacy_policy_url}>privacy policy</a>.
          </span>
        </div>
        {/* {stripe && isCardComplete && isExpiryComplete && isCvcComplete && ( */}
        <button
          className="payment-button"
          type="submit"

          disabled={details?.price == 0 ? false : (!stripe || !isCardComplete || !isExpiryComplete || !isCvcComplete || loading)}
        >
          {createOrderPassMutation?.isLoading ? (
            <CircularProgress size={24} sx={{ color: "#fff" }} />
          ) : (
            "Complete Order"
          )}
        </button>
        <Box alignSelf={"center"}>
          <img src="/assets/ssl-ribbon.png" width={'97'} style={{ margin: "0 20px" }} />
          <img src="/assets/cc.png" width={'147'} style={{ margin: "0 20px" }} />
          <img src="/assets/ssl.png" width={'120'} style={{ margin: "0 20px" }} />

        </Box>
        {/* )} */}
      </form>}
      {/* <BookingSucces details={details} productId={productId} /> */}
    </>
  );
};

export default PaymentForm;

import { Grid } from '@mui/material'
import React from 'react'
import QuillDataDisplay from '../components/dataDisplayview'

const PassDetails = ({ details }) => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <div className="total">
            <span style={{ fontSize: "23px" }}>{details?.entitled}</span>
          </div>
        </Grid>
        {details?.description !== "" ||
          (details?.description !== null && (
            <Grid item xs={12} md={12}>
              <QuillDataDisplay content={details?.description} />
            </Grid>
          ))}

        <Grid item xs={12} md={6}>
          <div className="total">
            <span>Valid for:</span>
            <span style={{ fontSize: "20px" }}>
              {details?.period_count} {details?.period?.entitled}
            </span>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="total">
            <span>Auto-renewal:</span>
            <span style={{ fontSize: "20px" }}>
              {details?.auto_renew ? "Yes" : "NO"}
            </span>
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <div className="total">
            <span>Price:</span>
            <span style={{ fontSize: "20px" }}>
              {details?.price == 0 ? "Free" : details?.price}
            </span>
          </div>
        </Grid>
      </Grid>
    );
}

export default PassDetails

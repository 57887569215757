import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  QueryClient,
  QueryClientProvider,
} from "react-query";
import "./styles/booking-stepper.scss";
import { AuthProvider } from "./context/authContext";
import { CssBaseline } from "@mui/material";
import { Toaster } from "react-hot-toast";
import Pass from "./Pages/Pass";

const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route
              path="pass/:productId"
              element={<Pass />}
            />
          </Routes>
        </BrowserRouter>

        <Toaster
          position="top-right"
          toastOptions={{ className: 'react-hot-toast' }}
        />

      </QueryClientProvider>
    </AuthProvider>
  );
};

export default App;

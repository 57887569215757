import { Box, Button, Grid, Link, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";

import * as yup from 'yup'



const yupSchema = yup.object({
  first_name: yup.string().required('First Name is required'),
  last_name: yup.string().required('Last Name is required'),
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Email is required'),
  phone_number: yup.string(),
  password: yup.string().required('Password is required'),
  repeat_password: yup.string().required('Repeat Password is required'),
});


const SignUpForm = ({
  signUpForm,
  setSignUpForm,
  formErrors,

}) => {
  const handleSubmit = () => {

  }

  
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      password: "",
      repeat_password: "",
    },
    validationSchema: yupSchema,
    onSubmit: handleSubmit,
  });

  const handleSignUpChange = (formName, event) => {
    setSignUpForm((prevValues) => ({
      ...prevValues,
      [formName]: event.target.value,
    }));
  };
  function renderArrayMultiline(arr = []) {
    if (!arr || !Array.isArray(arr)) {
      return
    }
  
    return arr?.map(e => {
      return (
        <>
          {e}
          <br />
        </>
      )
    })
  }
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
        // height: "100vh", // To vertically center in the viewport
      }}
    >
      <Grid container spacing={3} width={400}>
        <Grid item xs={12} sm={12}>
          <TextField
            fullWidth
            label="Email"
            value={signUpForm.email}
            variant="outlined"
            sx={{
              "& .MuiOutlinedInput-root": {
                borderColor: "transparent",
              },
              "& .MuiOutlinedInput-input": {
                padding: "12px 14px",
                backgroundColor: "white",
              },
            }}
            onChange={(e) => {
              handleSignUpChange("email", e);
            }}
            error={formErrors?.email}
            helperText={renderArrayMultiline(formErrors?.email)}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            fullWidth
            label="First Name"
            value={signUpForm.first_name}
            variant="outlined"
            sx={{
              "& .MuiOutlinedInput-root": {
                borderColor: "transparent",
              },
              "& .MuiOutlinedInput-input": {
                padding: "12px 14px",
                backgroundColor: "white",
              },
            }}
            onChange={(e) => {
              handleSignUpChange("first_name", e);
            }}
            error={formErrors?.first_name}
            helperText={renderArrayMultiline(formErrors?.first_name)}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            fullWidth
            label="Last Name"
            value={signUpForm.last_name}
            variant="outlined"
            sx={{
              "& .MuiOutlinedInput-root": {
                borderColor: "transparent",
              },
              "& .MuiOutlinedInput-input": {
                padding: "12px 14px",
                backgroundColor: "white",
              },
            }}
            onChange={(e) => {
              handleSignUpChange("last_name", e);
            }}
            error={formErrors?.last_name}
            helperText={renderArrayMultiline(formErrors?.last_name)}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default SignUpForm;

import { createContext, useEffect, useState } from "react";
import axiosClient from "../axiosClient";
import toast from "react-hot-toast";

type defaultProviderProps = {
  user: any;
  isLoggedIn: boolean;
  setUser: any;
  login: any;
  register: any;
  logout: any;
  resetPassword: any;
  loadingReset?: boolean
};

const defaultProvider = {
  user: null,
  isLoggedIn: false,
  setUser: () => null,
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  resetPassword: () => Promise.resolve()
};
const AuthContext = createContext<defaultProviderProps>(defaultProvider);

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(defaultProvider.user);
  const [isLoggedIn, setIsloggedIn] = useState<boolean>(
    defaultProvider.isLoggedIn
  );
  const [loadingReset, setLoadingReset] = useState(false)
  const [storedToken, setstoredToken] = useState("");
  const initAuth = async (): Promise<void> => {
    const storedToken = window.localStorage.getItem("token");
    setstoredToken(storedToken);
    if (storedToken) {
      await axiosClient
        .get("logged-in-user")
        .then(async (response) => {
          setUser({ ...response?.data?.result });
          setIsloggedIn(true);
        })
        .catch(() => {
          localStorage.removeItem("userData");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("token");
          setUser(null);
          setIsloggedIn(false);
        });
    }
  };
  useEffect(() => {
    initAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedToken]);

  const handleLogin = async (params, errorCallback) => {
    try {
      const response = await axiosClient.post("/client-login", params);

      setUser({ ...response.data.result });
      window.localStorage.setItem(
        "userData",
        JSON.stringify(response.data.result)
      );
      window.localStorage.setItem("token", response.data.result.token);
      window.localStorage.setItem(
        "client_secret",
        response.data.result.client_secret
      );
      window.localStorage.setItem(
        "client_id",
        response.data.result.id);
      setIsloggedIn(true);
      initAuth();
      return { success: true }; // Indicate success
    } catch (err) {
      if (errorCallback) errorCallback(err);
      return { success: false, message: err.response.data.erros }; // Indicate failure
    }
  };

  const handleSignUp = async (params, errorCallback) => {
    try {
      const response = await axiosClient.post("/clients/register", params);
      
      setUser({ ...response.data.data });
  
      window.localStorage.setItem('client_secret', response.data.data.client_secret)
      window.localStorage.setItem('client_id', response.data.data.id)
      setIsloggedIn(true);
      initAuth();
      return { success: true }; // Indicate success
    } catch (err) {
      if (errorCallback) errorCallback(err);
      return { success: false, message: err }; // Indicate failure
    }
  };


  const logout = () => {
    localStorage.removeItem("userData");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("token");
    setUser(null);
    setIsloggedIn(false);
  };

  const handleResetPassword = async (params, errorCallback) => {
    setLoadingReset(true)
    try {
      const response = await axiosClient.put('/reset', params);
      toast.success(response?.data?.message);
      setLoadingReset(false)
      return { success: true, response: response.data }; // Retourner la réponse
    } catch (err) {
      if (errorCallback) errorCallback(err);
      setLoadingReset(false)
      return { success: false, message: err.response?.data?.message || 'An error occurred' }; // Retourner l'erreur
    }
  }


  const values = {
    user,
    isLoggedIn,
    register: handleSignUp,
    setUser,
    login: handleLogin,
    logout,
    resetPassword: handleResetPassword,
    loadingReset
  };
  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};
export { AuthContext, AuthProvider };

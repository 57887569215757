import { Box, Button, CircularProgress, useTheme, } from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

const CustomBottomBar = ({
    steps,
    activeStep,
    handleNext,
    handleBack,
    isPending
}) => {
    const theme = useTheme();





    return (
        <Box sx={{
            width: "100%",
            position: "fixed",
            display: "flex",
            alignItems: "center",
            backgroundColor: 'var(--primary-color)',
            justifyContent: "space-between",
            py: "14px",
            bottom: 0,

        }}
            className='footer-container-custom hf-container'
        >
            {activeStep !== 0 ? (
                <Button size="small" sx={{ color: 'white', fontWeight: "700", fontSize: "16px" }} disabled={activeStep === 0} onClick={handleBack}>
                    {theme.direction === "rtl" ? (
                        <KeyboardArrowRight />
                    ) : (
                        <KeyboardArrowLeft />
                    )}
                    Back
                </Button>
            ) : <div></div>}



            {(activeStep !== steps.length - 1 && activeStep !== 4) ? (
                <Button
                    sx={{ color: 'white', fontWeight: "700", fontSize: "16px" }}
                    size="small"
                    onClick={handleNext}
                    // endIcon={ <CircularProgress size={15} />}
                >
                    Next
                    {isPending? 
                    <CircularProgress size={18} sx={{ml:2,color:"white"}}  />
                  :  theme.direction === "rtl" ? (
                        <KeyboardArrowLeft />
                    ) : (
                        <KeyboardArrowRight />
                    )}
                </Button>
            ) :
                <div>

                </div>
            }
        </Box >
    )
}

export default CustomBottomBar;

import { Box, Button, Card, CardContent, styled, Typography } from '@mui/material';
import React from 'react'


const BookingSucces = ({ productId, details }) => {


    function handleClick() {
        window.location.replace(`/pass/${productId}`);
    }
    const StyledLoginCard = styled(Card)(({ theme }) => ({
        height: "70%",
        boxShadow: "0px 4px 8px 8px rgba(183, 184, 184, 0.25)",
    }));
    return (
        <Box sx={{ width: "100%", maxWidth: 600, mx: "auto" }}>
            <StyledLoginCard>
                <CardContent
                    sx={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        justifyContent: "space-around",
                        py: 5
                    }}
                >
                    <Typography variant='h3' >Thank You!</Typography>
                    <Typography variant='h6' mt={5} >{details?.success_message} </Typography>

                    <Typography variant='h6' mt={1} >We'll see you soon!</Typography>

                    {/* <Box
                        sx={{ width: "100%", maxWidth: 400, mx: "auto", paddingTop: 2 }}>
                        <Button variant="contained" sx={{
                            backgroundColor: 'var(--primary-color)',
                            '&:hover': {
                                backgroundColor: 'var(--primary-color)',
                            },

                        }} onClick={handleClick} fullWidth>
                            Book again
                        </Button>
                    </Box> */}
                </CardContent>
            </StyledLoginCard></Box>
    )
}

export default BookingSucces
import {  Toolbar, Typography, AppBar } from "@mui/material";


const AppBarComponent = ({ passName, steps, activeStep}: any) => {


 
  return (
    <AppBar position="static" className="header-container-custom" sx={{
      backgroundColor:  'var(--primary-color)',
      height:"3.5rem"
    }}>
      <Toolbar>

        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {steps[activeStep]}
        </Typography>
        <Typography variant="h6" component="div">
          {passName}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};
export default AppBarComponent;

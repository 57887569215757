import { Box, Button, Divider, Grid, TextField, Typography } from '@mui/material';
import React from 'react';

const SharingPass = ({ sharingWith, setSharingWith }) => {
  // Function to handle input changes
  const handleInputChange = (value: any, subKey: string, index: number) => {
    const updatedSharingWith = [...sharingWith];
    updatedSharingWith[index][subKey] = value;
    setSharingWith(updatedSharingWith);
  };

  // Function to add a new share
  const handleAddShare = () => {
    const newShare = { first_name: '', last_name: '', email: '' };
    setSharingWith([...sharingWith, newShare]);
  };

  // Function to remove a share
  const handleRemoveAccess = (index: number) => {
    const updatedSharingWith = sharingWith.filter((_, i) => i !== index);
    setSharingWith(updatedSharingWith);
  };
console.log('sharingWith',sharingWith)
  return (
    <div>
      {sharingWith?.map((access: any, index: number) => (
        <Grid container key={index} spacing={2}>
          <Grid item sx={{padding:0}} xs={12} md={6}>
            <TextField
              fullWidth
              label="First name"
              value={access.first_name}
              variant="outlined"
              sx={{ backgroundColor: 'white' }}
              onChange={(e) => handleInputChange(e.target.value, 'first_name', index)}
            />
          </Grid>
          <Grid item sx={{padding:0}} xs={12} md={6}>
            <TextField
              fullWidth
              label="Last name"
              value={access.last_name}
              variant="outlined"
              sx={{ backgroundColor: 'white' }}
              onChange={(e) => handleInputChange(e.target.value, 'last_name', index)}
            />
          </Grid>
          <Grid item sx={{padding:0}} xs={12} md={12}>
            <TextField
              fullWidth
              label="Email"
              value={access.email}
              variant="outlined"
              sx={{ backgroundColor: 'white' }}
              onChange={(e) => handleInputChange(e.target.value, 'email', index)}
            />
          </Grid>
         
          {sharingWith.length > 1 ? (
            <Grid item sx={{padding:0}} xs={12} display="flex" justifyContent="end">
              <Box sx={{ cursor: 'pointer', width: 'fit-content' }} onClick={() => handleRemoveAccess(index)}>
                <Typography color="error">Remove</Typography>
              </Box>
            </Grid>
          ) : null}
           <Grid item sx={{padding:0}} xs={12} md={12} mb={1}>
            <Divider />
          </Grid>
        </Grid>
      ))}

      <Grid container>
        <Grid item sx={{padding:0}} xs={12}>
          <button
            style={{ backgroundColor: 'var(--primary-color)', color: 'white', width: '100%' , 
                outline:"none", border:"none" , height:"40px", fontSize:"20px", borderRadius:"5px"}}
            onClick={handleAddShare}
          >
            Add
          </button>
        </Grid>
      </Grid>
    </div>
  );
};

export default SharingPass;

import axios from "axios";
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import { Calendar, Credentials, TimeModel } from "./_models";
import axiosClient from "../../axiosClient";
import toast from "react-hot-toast";

const API_URL = process.env.REACT_APP_API_URL;




const useGetPasses = ({ id, date }) => {
  return useQuery({
    queryKey: [`pass/${id}`],
    queryFn: () =>
      axios
        .get(`${API_URL}/public/pass/${id}`)
        .then((res) => res.data),
    enabled: !!id,
  });
};



const login = async (credentials: Credentials) => {
  const response = await axios.post(`${API_URL}/client-login`, credentials);
  return response.data;
};

export const useLogin = () => {
  return useMutation(login);
};



const useGetStripePublicKey= () => {
  return useQuery({
    queryKey: [`stripe-public-key`],
    queryFn: () =>
      axios
        .get(`${API_URL}/stripe-public-key`)
        .then((res) => res.data),

  });
};
const useGetExtraData= () => {
  return useQuery({
    queryKey: [`extra-data`],
    queryFn: () =>
      axios
        .get(`${API_URL}/extra-data`)
        .then((res) => res.data),

  });
};


const useGetLabelsForAdvancedNotice= () => {
  return useQuery({
    queryKey: [`labels`],
    queryFn: () =>
      axios
        .get(`${API_URL}/labels/type/1`)
        .then((res) => res.data),

  });
};
const useCreateOrdersPass = () => {
  const queryClient = useQueryClient();

  const createOrdersMutation = useMutation({
    mutationFn: (values) => {
      return axios.post(`${API_URL}/public/pass`, values);
    },
    onSuccess: (data) => {
      // @ts-ignore
      
         // @ts-ignore
      toast.success(data?.data?.message?.entitled)
      queryClient.invalidateQueries({ queryKey: ["orders"] });
    },
    onError: (error) => {
      
      // @ts-ignore
      toast.error(error?.response?.data?.message)
     
    },
  });

  return createOrdersMutation;
};

export {
  useGetPasses,
  useGetStripePublicKey,
  useGetExtraData,
  useCreateOrdersPass
};

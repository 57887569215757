import React, { useEffect, useRef } from 'react'

const customEditorStyle = {
  width: '100%',
  height: '100%',
  backgroundColor: 'white',
  border: 'none',
  minHeight: 200,
  cursor: 'pointer',
  padding: '20px' // Example: add padding
}

const QuillDataDisplay = ({ content }) => {
  const contentRef = useRef(null)
  console.log('ccccc', content)
  useEffect(() => {
    if (contentRef.current) {
      // Set the HTML content directly
      contentRef.current.innerHTML = content

      // Apply custom CSS
      const styleTag = document.createElement('style')
      styleTag.innerHTML = content
      contentRef.current.appendChild(styleTag)
    }

    // Clean up: remove the style tag when the component unmounts
    return () => {
      if (contentRef.current) {
        contentRef.current.innerHTML = ''
      }
    }
  }, [content])

  return (
    <div style={{ width: '100%' }}>
      <div ref={contentRef} style={customEditorStyle} />
    </div>
  )
}

export default QuillDataDisplay
